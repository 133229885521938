import * as React from 'react'
import { useState, useCallback } from 'react'
import { graphql } from 'gatsby'
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../layout'
import SEO from '../../components/seo'
import PostList from '../../components/post-list'

import { INode } from '../../types/mdx'

import { encodeUnicode } from '../../utils/encoded'

import * as styles from './index.module.scss'

declare function filtered(args: INode[]): INode[]

// const SearchPage: React.FC<Props> = ({ data }) => {
function SearchPage({ data }: IProps) {
  const posts = data.allMdx.edges

  const [value, setValue] = useState('')
  const [isTitleOnly, setTitleOnly] = useState(true)

  const filteredPosts = useCallback<typeof filtered>(
    (posts) =>
      posts.filter((post) => {
        const { frontmatter, body } = post.node
        const { title } = frontmatter

        const upperValue = value.toLocaleUpperCase()

        const includedTitle = title.toLocaleUpperCase().includes(upperValue)

        if (isTitleOnly) {
          return includedTitle
        }

        return (
          includedTitle ||
          body.toLocaleUpperCase().includes(upperValue) ||
          body
            .toLocaleUpperCase()
            .includes(encodeUnicode(value).toLocaleUpperCase())
        )
      }),
    [value, isTitleOnly]
  )

  return (
    <Layout>
      <SEO title={`SEARCH`} />

      <div id={styles.search}>
        <div className={styles.searchInnerWrap}>
          <div className={styles.inputWrap}>
            <Fa icon={faSearch} />

            <input
              type='text'
              id='search-input'
              name='search'
              value={value}
              placeholder='Search'
              autoComplete='off'
              autoFocus
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setValue(e.currentTarget.value)
              }}
            />

            <div className={styles.searchToggle}>
              <span
                className={isTitleOnly ? styles.selected : ''}
                onClick={() => setTitleOnly(true)}
                onKeyDown={() => void 0}
                role='button'
                tabIndex={0}
              >
                in Title
              </span>
              <span
                className={isTitleOnly ? '' : styles.selected}
                onClick={() => setTitleOnly(false)}
                onKeyDown={() => void 0}
                role='button'
                tabIndex={0}
              >
                in Title+Content
              </span>
            </div>
          </div>

          {value !== '' && !filteredPosts.length ? (
            <span className={styles.noResult}>No search results</span>
          ) : null}

          <PostList posts={value === '' ? posts : filteredPosts(posts)} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          body
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            update(formatString: "MMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`

interface IProps {
  data: {
    allMdx: {
      edges: INode[]
    }
  }
}

export default SearchPage
