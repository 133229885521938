export const encodeUnicode = (value: string): string => {
  let unicodeString = ''

  for (let i = 0; i < value.length; i++) {
    const char = value.charCodeAt(i)

    // ' ' === 32 (10) === 20 (16)
    if (char === 32) {
      unicodeString += ' '
      continue
    }

    let theUnicode = char.toString(16).toUpperCase()
    while (theUnicode.length < 4) {
      theUnicode = '0' + theUnicode
    }
    theUnicode = '\\u' + theUnicode

    unicodeString += theUnicode
  }

  return unicodeString
}
